var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"only-owner":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-4 pb-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_vm._v(" Задания: ")]),_c('v-col',{staticClass:"text-right"},[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"color":"primary","depressed":"","height":"36","large":""}},'v-btn',attrs,false),on),[_vm._v(" Создать ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Создать задание")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.gameModes,"clearable":"","hide-details":"auto","label":"Режим"},model:{value:(_vm.gameMode),callback:function ($$v) {_vm.gameMode=$$v},expression:"gameMode"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Цель"},model:{value:(_vm.goal),callback:function ($$v) {_vm.goal=_vm._n($$v)},expression:"goal"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Минимальный порог участия"},model:{value:(_vm.min),callback:function ($$v) {_vm.min=_vm._n($$v)},expression:"min"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Призовой фонд"},model:{value:(_vm.prize),callback:function ($$v) {_vm.prize=_vm._n($$v)},expression:"prize"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Задание"},model:{value:(_vm.task),callback:function ($$v) {_vm.task=_vm._n($$v)},expression:"task"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.rulesItems,"clearable":"","hide-details":"auto","label":"Правила","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.rules.length - 1)+" других) ")]):_vm._e()]}}]),model:{value:(_vm.rules),callback:function ($$v) {_vm.rules=$$v},expression:"rules"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" Создать ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":[],"items-per-page":5},scopedSlots:_vm._u([{key:"item.rules",fn:function(ref){
var item = ref.item;
return [(item.rules[0])?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.rules[0]))])]):_vm._e(),(item.rules[1])?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(item.rules.length - 1)+" других) ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY, HH:mm'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-none ma-1",attrs:{"color":item.status ? '' : 'primary',"depressed":"","height":"36","large":""}},[_vm._v(" "+_vm._s(item.status ? 'Выключить' : 'Включить')+" ")]),_c('v-btn',{staticClass:"text-none ma-1",attrs:{"color":"primary","depressed":"","height":"36","large":""},on:{"click":function($event){item.id === _vm.taskId ? _vm.showParticipants() : _vm.showParticipants(item.id)}}},[_vm._v(" "+_vm._s(item.id === _vm.taskId ? 'Скрыть участников' : 'Показать участников')+" ")])]}}],null,true)})],1)],1),_c('v-col',{staticClass:"text-center text-h5",attrs:{"cols":"12"}},[_vm._v(" Список участников ")]),(_vm.taskId)?_c('v-col',[_c('v-card',{staticClass:"px-4 pb-3",attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headersParticipants,"items":[],"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogParticipants),callback:function ($$v) {_vm.dialogParticipants=$$v},expression:"dialogParticipants"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Редактирование")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Задание"},model:{value:(_vm.editedUserTask.task),callback:function ($$v) {_vm.$set(_vm.editedUserTask, "task", $$v)},expression:"editedUserTask.task"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.rulesItems,"clearable":"","hide-details":"auto","label":"Правила","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.editedUserTask.rules.length - 1)+" других) ")]):_vm._e()]}}],null,false,499539517),model:{value:(_vm.editedUserTask.rules),callback:function ($$v) {_vm.$set(_vm.editedUserTask, "rules", $$v)},expression:"editedUserTask.rules"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogParticipants = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogParticipants = false}}},[_vm._v(" Сохранить ")])],1)],1)],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY, HH:mm'))+" ")]}},{key:"item.rules",fn:function(ref){
var item = ref.item;
return [(item.rules[0])?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.rules[0]))])]):_vm._e(),(item.rules[1])?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(item.rules.length - 1)+" других) ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"depressed":"","height":"36","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.editUserTask(item)}}},[_vm._v(" Редактировать ")])]}}],null,true)})],1)],1):_c('v-col',{staticClass:"text-center text-subtitle-2"},[_vm._v(" Выберите задание, чтобы увидеть результат ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }