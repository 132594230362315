<template>
  <AppRow only-owner>
    <template v-slot>
      <v-col cols="12">
        <v-card
          class="px-4 pb-3"
          flat
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                Задания:
              </v-col>
              <v-col class="text-right">
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      class="text-none"
                      color="primary"
                      depressed
                      height="36"
                      large
                      v-on="on"
                    >
                      Создать
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Создать задание</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-select
                              v-model="gameMode"
                              :items="gameModes"
                              clearable
                              hide-details="auto"
                              label="Режим"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              v-model.number="goal"
                              hide-details="auto"
                              label="Цель"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              v-model.number="min"
                              hide-details="auto"
                              label="Минимальный порог участия"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              v-model.number="prize"
                              hide-details="auto"
                              label="Призовой фонд"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model.number="task"
                              hide-details="auto"
                              label="Задание"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="rules"
                              :items="rulesItems"
                              clearable
                              hide-details="auto"
                              label="Правила"
                              multiple
                            >
                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                  <span>{{ item }}</span>
                                </v-chip>
                                <span
                                  v-if="index === 1"
                                  class="grey--text text-caption"
                                >
                                  (+{{ rules.length - 1 }} других)
                                </span>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />

                      <v-btn
                        color="primary"
                        text
                        @click="close"
                      >
                        Отмена
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="close"
                      >
                        Создать
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="[]"
            :items-per-page="5"
          >
            <template v-slot:[`item.rules`]="{ item }">
              <v-chip v-if="item.rules[0]">
                <span>{{ item.rules[0] }}</span>
              </v-chip>
              <span
                v-if="item.rules[1]"
                class="grey--text text-caption"
              >
                (+{{ item.rules.length - 1 }} других)
              </span>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                :color="item.status ? '' : 'primary'"
                class="text-none ma-1"
                depressed
                height="36"
                large
              >
                {{ item.status ? 'Выключить' : 'Включить' }}
              </v-btn>
              <v-btn
                class="text-none ma-1"
                color="primary"
                depressed
                height="36"
                large
                @click="item.id === taskId ? showParticipants() : showParticipants(item.id)"
              >
                {{ item.id === taskId ? 'Скрыть участников' : 'Показать участников' }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col
        class="text-center text-h5"
        cols="12"
      >
        Список участников
      </v-col>

      <v-col v-if="taskId">
        <v-card
          class="px-4 pb-3"
          flat
        >
          <v-data-table
            :headers="headersParticipants"
            :items="[]"
            :items-per-page="5"
          >
            <template v-slot:top>
              <v-dialog
                v-model="dialogParticipants"
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Редактирование</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedUserTask.task"
                            label="Задание"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedUserTask.rules"
                            :items="rulesItems"
                            clearable
                            hide-details="auto"
                            label="Правила"
                            multiple
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index === 0">
                                <span>{{ item }}</span>
                              </v-chip>
                              <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                              >
                                (+{{ editedUserTask.rules.length - 1 }} других)
                              </span>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="dialogParticipants = false"
                    >
                      Отмена
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="dialogParticipants = false"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
            </template>

            <template v-slot:[`item.rules`]="{ item }">
              <v-chip v-if="item.rules[0]">
                <span>{{ item.rules[0] }}</span>
              </v-chip>
              <span
                v-if="item.rules[1]"
                class="grey--text text-caption"
              >
                (+{{ item.rules.length - 1 }} других)
              </span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                class="text-none"
                depressed
                height="36"
                large
                @click.stop="editUserTask(item)"
              >
                Редактировать
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col
        v-else
        class="text-center text-subtitle-2"
      >
        Выберите задание, чтобы увидеть результат
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';

export default {
  components: {
    AppRow,
  },
  data() {
    return {
      dialog: false,
      gameMode: '',
      gameModes: [],
      goal: '',
      min: '',
      prize: '',
      task: '',
      rules: [],
      rulesItems: [],
      headers: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Режим:', value: 'gameMode', class: 'white--text text-subtitle-2' },
        { text: 'Цель:', value: 'goal', class: 'white--text text-subtitle-2' },
        { text: 'Минимальный порог участия:', value: 'min', class: 'white--text text-subtitle-2' },
        { text: 'Призовой фонд:', value: 'prize', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Правила:', value: 'rules', class: 'white--text text-subtitle-2' },
        { text: 'Дата создания:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      taskId: '',
      headersParticipants: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Имя:', value: 'username', class: 'white--text text-subtitle-2' },
        { text: 'Дата участия:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: 'Попыток:', value: 'attempts', class: 'white--text text-subtitle-2' },
        { text: 'Задание:', value: 'task', class: 'white--text text-subtitle-2' },
        { text: 'Правила:', value: 'rules', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      dialogParticipants: false,
      editedUserTask: {},
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.gameMode = '';
        this.goal = '';
        this.min = '';
        this.prize = '';
        this.task = '';
        this.rules = [];
      });
    },
    showParticipants(id) {
      this.taskId = id;
    },
    editUserTask(item) {
      this.editedUserTask = { ...item };
      this.dialogParticipants = true;
    },
  },
};
</script>
